.sidebar{
  width: 230px;
  height: 100%;
  border-radius: 0px;
  border: none;
  .logo {
    box-shadow: none;
    margin-top: 15px;
    padding: 0;
    margin-bottom: 40px;
    &:before{
        display: none;
    }

    a.logo-normal {
      padding: 0;
      line-height: 20px;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 20px;
    }

    a.logo-mini {
      float: left;
      width: 20px;
      height: 20px;
      text-align: center;
      margin-left: 20px;
      margin-right: 10px;
      position: relative;
      padding: 0;

      .logo-img {
        width: 20px;
        min-height: 20px;
        img {
          width: 20px;
          height: 20px;
          top: 0;
        }
      }
    }
  }

  .nav {
    margin-top: 0;
    li {
      &:hover {
        > a {
          background: transparent;
        }
      }

      > a {
        padding-left: 10px;
        padding-right: 10px;
        height: 20px;
        box-sizing: content-box;
        margin: 0 10px;
        background: transparent;
        opacity: .75;
        i {
          line-height: 20px;
          width: 20px;
          height: 20px;
          font-size: 20px;
        }

        p {
          line-height: 20px;
          font-size: 14px;
          font-weight: 500;
          text-transform: none;
        }
      }

      &.active > a {
        opacity: 1;
        background: rgba(255, 255, 255, .95);
        color: rgb(63, 70, 88);

        p {
          color: rgb(63, 70, 88);
        }

        i {
          &:after {
            //content: '';
            //display: block;
            //height: 8px;
            //width: 8px;
            //background: dodgerblue;
            //border-radius: 50%;
            //position: absolute;
            //left: -3px;
            //top: 50%;
            //transform: translateY(-50%);
          }
        }
      }

      &:last-child {
        margin-top: 50px;
      }
    }
  }

  .sidebar-wrapper {
    width: 230px;
      overflow-x: hidden;
      box-shadow: none;
      height: calc(100% - 95px);
      display: flex;
      justify-content: space-between;
      flex-direction: column;
  }
}

.sidebar,
.bootstrap-navbar{
  &:after {
    background: #295d8f;
    background: linear-gradient(to bottom,#295d8f, #1e2957);
  }

  .logo {
    &:before{
        display: none;
    }
  }

  .sidebar-wrapper {
        overflow-x: hidden;
  }
}

body {
  &.sidebar-mini {
    .sidebar {
      .nav {
        li {
          &.active > a {

          }
        }
      }
    }
  }
}

.sidebar-mini {
  .sidebar,
  .sidebar .sidebar-wrapper {
    width: 60px;
  }
}

@media (max-width: $screen-md){

  html {
    .main-panel {
      &:after {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #333;
        opacity: 0;
        transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
        pointer-events: none;
      }
    }
    &.nav-open {
      .main-panel {
        &:after {
          opacity: .7;
          pointer-events: all;
        }
      }
    }
  }


  .sidebar {
    top: 60px;
    width: 230px!important; // TODO: Remove important by creating proper selector.
    transform: translate3d(250px, 0, 0);
    height: calc(100% - 80px);
    margin: 10px;
    border-radius: 5px;

    &:after {
      background: #E9EBF2;
      background: linear-gradient(to bottom,#fff, #E9EBF2);
    }

    .sidebar-wrapper {
      width: 230px!important; // TODO: Remove important by creating proper selector.
    }

    .nav {
      ul {
        li {
          a {
            color: #333;
            opacity: 1;
          }

          &:last-child {
            margin-top: 0;
          }
        }
      }
      p {
        color: #333;

        .caret {
          border-bottom-color: #295d8f;
          border-top-color: #295d8f;
        }
      }
    }
  }

  .main-panel {
    transform: none!important; // TODO: Remove important by creating proper selector.

    .navbar {
      height: 60px;
      background: linear-gradient(to left,#295d8f, #1e2957);
      top: 0;
      position: fixed;
      width: 100%;
      z-index: 1;

      >div {
        height: 100%;
      }

      .navbar-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
          position: absolute;
          left: 20px;
        }

        .btn-menu {
          position: absolute;
          right: 20px;
        }
      }
    }
  }
}
