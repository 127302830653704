ol {
    padding-left: 10px;
    &.list-welcome {
        list-style: none;
        counter-reset: item;
        li {
            counter-increment: item;
            &:before {
                margin-right: 5px;
                content: counter(item) ".";
                color: black;
                font-weight: bold;           
            }
        }
    }
}

ul {
    &.list-unstyled {
        list-style: none;
        padding: 0;
        margin: 0;
    }
}