.text-primary, .text-primary:hover,
.text-info, .text-info:hover{
  color: $defaultColor !important;
}
.text-success, .text-success:hover{
  color: $success-color !important;
}
.text-warning, .text-warning:hover{
  color: $warning-color !important;
}
.text-danger, .text-danger:hover{
  color: $actionColor !important;
}
.text-muted, .text-muted:hover{
  color: #6f6f6f !important;
}