.modal-header {
  line-height: 1;
  padding: 40px 30px 10px 30px;
  margin: 0;
  border: none;
  font-weight:700;
  @extend h4;
}

.modal-body {
  padding: 30px;
  p {
    margin-top: 0;
    margin-bottom: 20px;

    &:last-child {
      margin: 0;
    }
  }
}

.modal-footer {
  padding: 30px;
  border: none;
}