$defaultColor: #007AFF;
$actionColor: #FE5672;

$sidebarColorHover: rgb(112, 197, 224);

.input-group .form-control {
    z-index: auto;
}

.form-advice-wrapper {
    position: sticky;
    top: 15px;
}

.notifications-wrapper {
    .notifications-br {
        pointer-events: none;
        .notification {
            pointer-events: all;
        }
    }
}

#tusConsejos {
    table.advisor-table.table {
        tr:last-child .table-element-actions {
            .menu-remember.dropdown {
                &.open {
                    ul {
                        display: block;
                    }
                }
                ul {
                    display: none;
                }
            }
        }
    }
}

a {
    color: $defaultColor;
}

.text-navy {
    color: #3D4457!important;
}

.raised {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)!important;
}

.font {
    &-weight {
        &-500 {
            font-weight: 500!important;
        }

        &-600 {
            font-weight: 600!important;
        }
    }
}

.table-element-actions {
    .dropdown-toggle {
        padding: 0 6px;
        font-size: 16px;
    }
}

span.new-advice-bubble {
    position: absolute;
    top: 6px;
    left: 0;
    background: red;
    color: white;
    font-weight: bold;
    padding: 2px 6px;
    font-size: 10px;
    border-radius: 20px;
}

.Select-menu-outer {
    width: calc(100% - 1px)!important;
}

.home-pills-symbol {
    background: #1e2957;
    //background: #295d8f;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: white;
}

.font-size-16 {
    font-size: 16px!important;
}

.font-size-14 {
    font-size: 14px!important;
}

.font-size-12 {
    font-size: 12px!important;
}


.border-none {
    border: none!important;
}

.sweet-alert {
    h2 {
        font-size: 25px;
    }

    >div:first-child {
        height: 50px!important;
        width: 50px!important;

        >span:first-child {
            height: 20px!important;
        }
    }
}

.navbar-default {
    border-bottom: transparent;
}

.main-header {
    margin-bottom: 15px;
    button {
        display: inline;
        float: right;
    }
    h2 {
        margin: 0;
        padding: 0;
        font-size: 30px;
    }
}

.table-filters {
margin-bottom: 15px;
}

.table-filter {
    border: 1.5px solid $defaultColor;
    border-radius: 5px;
    padding: 4px 10px;
    margin-bottom: 0.5em;
    margin-right: 10px;
    height: 35px;
}

.table-action {
    float: right;
    color: $defaultColor;
    cursor: pointer;
}

.table-element-actions {
    .btn {
        border: none;
        padding: 0 6px;
        font-size: 8px;
        color: $defaultColor;
    }
    .btn:hover {
        color: black;
    }
}

.help-msg {
    i {
        border: none;
        padding: 0 0 10px;
        font-size: 2rem;
        color: $defaultColor;
    }
}

.table-resume {
    font-size: 18px;
    font-weight: bold;
    float: right;
    text-align: right;
    padding: 10px 5px;
    border: 1px solid lightgray;
    border-bottom: none;
    width: 100%;
}

.print-dropdown.float-md-right .dropdown-menu {
    right: 0;
    left: auto;

    &::before,
    &::after {
        left: auto;
        right: 12px;
    }
}

@media (max-width: 991px) {
    .print-dropdown .dropdown-menu {
        background: #FFF;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)!important;
        &::before,
        &::after {
            display: none;
        }
    }
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.cursor-pointer {
    cursor: pointer!important;
}

.text-underline {
    text-decoration: underline!important;
}

.advisor-table > tbody > tr > td {
    vertical-align: top !important;
}

.advice-description {
    color: #9A9A9A;
    font-weight: normal;
    font-size: 12px;
}
.card .form-group .row div > label {
    font-size: 12px;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-weight: normal;
}

.card {
    .form-group {
        label {
            text-transform: uppercase;
            font-size: 14px;
            font-weight: normal;
            margin: 0;
        }
    }
}

.btn-neutral.btn-fill, .btn-neutral.btn-fill:hover, .btn-neutral.btn-fill:focus {
    color: #777;
}

.custom-form-label {
    line-height: 4rem;
}
.sugestion-form-label {
    text-transform: none !important;
    padding-left: 40px;
    font-style: italic;
}

.text-bold {
    font-weight: bold !important;
}

.btn:hover {
    color: #fff;
}

.btn-sm {
    border-radius: 4px;
}

.btn-light:hover {
    color: #000;
}
.btn-acciones {
    color: #ffffff;
    background-color: $actionColor !important;
    border-color: $actionColor !important;
}

.action-default {
    color: #ffffff !important;
    background-color: $defaultColor !important;
    border-color: $defaultColor !important;
    font-weight: normal !important;
}

.dropdown-incomes ul {
    list-style-type: none;
    li {
        float: right;
    }
}

.card .content .table-responsive {
    padding: 0;
}

.tooltip {
    font-size: 12px;

    &.in {
        opacity: 1;
    }

    .tooltip-inner {
        background: white;
        padding: 15px;
        border-radius: 0;
        color: #333;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        border: 1px solid rgba(0,0,0,0.16);
    }

    &.right {
        .tooltip-inner {
            text-align: left;
            &:after,
            &:before {
                top: 50%;
                transform: translateY(-50%);
                border-top: 8px solid rgba(0, 0, 0, 0);
                border-bottom: 8px solid rgba(0, 0, 0, 0);
            }

            &:after {
                border-right-color: #fff;
            }
        }
    }
}

//.sidebar {
//    .sidebar-wrapper {
//        .nav {
//            li {
//                &:hover,
//                &.active {
//                    background: $sidebarColorHover;
//                    > a {
//                        background: none
//                    }
//                }
//                > a {
//                    opacity: 1;
//                    transition: none;
//
//                    p{
//                        transition: none;
//                    }
//                }
//                &.nav-link-referral {
//                    i {
//                        color: $actionColor;
//                    }
//                    p {
//                        color: #fff;
//                    }
//
//                    &:hover {
//                        > a {
//                        i {
//                          color: lighten( $actionColor, 10% );
//                        }
//                      }
//                    }
//                }
//            }
//        }
//    }
//}

.error-label {
    display: block;
}

/*
 Visual hack for NoAutonomo stepzilla buttons.
 TODO: Remove this hack by removing stepzilla and refactoring both InformeRenta y NoAutonomo steps.
*/
#noAutonomoStepsWrapper,
#informeRentaStepsWrapper {
    .footer-buttons{
        padding-right: 0;
        padding-left: 0;
    }
}

.multi-step {
    .wizard-step {
        padding: 0;
    }
}

.new-advices {
    background: red;
    color: white;
    font-weight: bold;
    padding: 3px 6px;
    font-size: 10px;
    border-radius: 20px;
    user-select: none;
}

.missed-model {
    color: $actionColor;
    font-size: 18px;
    cursor: pointer;

}

@media (min-width: 768px) {
    .container.container-onboarding  {
        width: 650px;
    }
}

@media (min-width: 992px) {
    .container.container-onboarding  {
        width: 870px;
    }
}

@media (min-width: 1200px) {
    .container.container-onboarding  {
        width: 1070px;
    }
}

.nav {
    > li.menu-print {
        > ul {
            margin-top: 13px!important;
        }

        > a {
            padding: 0;
            color: #fff;
            font-weight: bold;
            background: transparent!important;

            p,
            i {
                font-size: 14px;
            }
        }
    }
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Add animation to "page content" */
.animate-bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s
}

@-webkit-keyframes animatebottom {
    from { bottom:0; opacity:0 }
    to { bottom:0; opacity:1 }
}

@keyframes animatebottom {
    from{ bottom:0; opacity:0 }
    to{ bottom:0; opacity:1 }
}
.rowOferta {
    .card {
        -webkit-box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.75);
    }
}
.video-responsive{
    position: relative;
    padding-bottom: 56.25%;
    padding-top:30px;
    height:0;
    overflow: hidden;
    iframe, object, embed{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
