.OverlayInfo {
  cursor: pointer;

  i.fa.fa-info {
    color: #FFA534;
    border-radius: 3px;
    border: 1px solid #FFA534;
  }
}

.OverlayInfo__Popover {
  &.popover {
    &.right {
      .arrow {
        &:after {
          top: 0;
          left: 2px;
          border-right-color: rgb(255, 188, 103);
        }
      }
    }
  }
  .popover-content {
    border: 1px solid rgb(255, 188, 103);
    background: white;
    border-radius: 4px;
    padding: 20px;
    @extend .raised;
  }
}