.btn {
    &.btn-danger.btn-fill {
        background: #EE3756;
        //background: linear-gradient(to right, #FE5672, #EE3756, #DE314E);
        border: none;
    }
    &.btn-info.btn-fill {
        background:#007AFF;
        //background: linear-gradient(to right, #007AFF, #1885E9, #1479D7);
        border: none;
    }
    &.btn-icon-circle {
        background: #fff;
        border: none;
        width: 30px;
        height: 30px;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        i {
            margin-left: 1px;
            width: auto;
            &.fa-plus {
                font-size: 17px!important;
            }
        }
    }
    &.btn-menu {
        background: rgba(255, 255, 255, 0.95);
        opacity: 1;
        border-radius: 30px;
        padding: 0;
        height: 30px;
        width: 30px;
        border: none;
        position: relative;

        &.is-closed {
            &:after,
            &:before {
                transform: rotate(0) translate(0);
            }

            &:before {
                left: 11px;
            }

            &:after {
                left: 17px;
            }
        }

        &:hover {
            background: rgba(255, 255, 255, 1);

            &:before {
                transform: rotate(30deg);
            }

            &:after {
                transform: rotate(-30deg);
            }

            &.is-closed {
                &:before {
                    transform: translate(-1px);
                }

                &:after {
                    transform: translate(1px);
                }
            }
        }

        &:after,
        &:before {
            content: '';
            display: block;
            height: 15px;
            width: 2px;
            background: #3f4658;
            position: absolute;
            top: 8px;
            transition: transform .2s ease-in-out;
        }

        &:before {
            left: 14px;
            transform: rotate(45deg);
        }

        &:after {
            left: 14px;
            transform: rotate(-45deg);
        }
    }

    &.btn-cta {
        color: #333;
        background: transparent;
        padding: 0;
        border: none;
        font-size: 20px;
        font-weight: bold;
        > img {
            height: 19px;
        }
        &:hover {
            text-decoration: underline;
            > img {
                animation: swing 3s infinite;
            }
        }
        &.is-default {
            color: $defaultColor;
        }
    }
    &.btn-user-back {
        margin-top: 0;
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        padding: 0;
        border: none;
        opacity: 1;
        color: #777;
        img {
            height: 13px;
            margin-right: 5px;
        }
    }

    &.btn-simple{
        @include btn-simple($black-color);
    }
}

@media (max-width: $screen-md){
    .btn {
        &.btn-menu {
            transform: rotate(90deg);
        }
    }
}

@keyframes swing {
    0% {transform: translateX(0);}
    50% {transform: translateX(2px);}
    100% {transform: translateX(0);}
}