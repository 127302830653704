
.custom-table {
  thead {
    tr {
      th {
        padding-right: 20px;
        background-repeat: no-repeat;
        background-position: 100%;

        &.sort-asc {
          background-image: url("../img/sort_asc.png");
          cursor: pointer;
        }
        &.sort-desc {
          background-image: url("../img/sort_desc.png");
          cursor: pointer;
        }
        &.sort-both {
          background-image: url("../img/sort_both.png");
          cursor: pointer;
        }
      }
    }
  }
}

.impuestos-table {
    tr.missed-model {
      background-color: rgba(255, 156, 156, 0.3);
    }
}

.table-impuestos-free {
    font-size: 16px;
    tbody {
        tr {
            td {
                padding: 10px;
                &:first-child {
                    font-size: 12px;
                    text-transform: uppercase;
                    color: #9A9A9A;
                    font-weight: 300;
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }
        }
    }
}