.call-offer {
  margin: 0 auto;
  //width: 260px;

  .photo {
    width: 150px;
    border-radius: 50%;
    box-shadow: 0 0 6px black;
  }

  .welcome-text {
    h5 {
      line-height: 20px;
    }
  }
}