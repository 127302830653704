/*! =========================================================
 *
 * Light Bootstrap Dashboard PRO React - v1.1.0 (LBD V1.3.0)
 *
 * =========================================================
 *
 * Copyright 2017 Creative Tim
 * Available with purchase of license from  https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-angular2
 *
 *
 * ========================================================= */


// variables and mixins
@import "lbd/variables";
@import "lbd/mixins";


//plugins css
@import "lbd/plugins/animate";
@import "lbd/plugins/bootstrap-select";
@import "lbd/plugins/bootstrap-table";
@import "lbd/plugins/chartist";
@import "lbd/plugins/code-mirror";
@import "lbd/plugins/datatables.net";
@import "lbd/plugins/datetime-picker";
@import "lbd/plugins/fixed-plugin";
@import "lbd/plugins/jquery.jvectormap";
@import "lbd/plugins/nouislider";
@import "lbd/plugins/react-big-calendar";
@import "lbd/plugins/react-bootstrap-switch";
@import "lbd/plugins/react-stepzilla";
@import "lbd/plugins/react-table";
@import "lbd/plugins/sweetalert2";
@import "lbd/plugins/tags";


// Core CSS
@import "lbd/typography";
@import "lbd/sidebar-and-main-panel";
@import "lbd/buttons";
@import "lbd/misc";
@import "lbd/inputs";
@import "lbd/progress-bars";
@import "lbd/sliders";
@import "lbd/forms";
@import "lbd/alerts";
@import "lbd/tables";
@import "lbd/tooltips-and-popovers";
@import "lbd/checkbox-radio-switch";
@import "lbd/navbars";
@import "lbd/footers";
@import "lbd/offers";

@import "lbd/header";


// Fancy Stuff
@import "lbd/social-buttons";
@import "lbd/dropdown";

@import "lbd/cards";

@import "lbd/collapse";
@import "lbd/carousel";

// @import "lbd/plugins/datetime-picker";

// SCSS for Pages
@import "lbd/pages.scss";

// Responsive adjustments
@import "lbd/responsive";
@import "lbd/tabs-navs-pagination";

@import "declarando-overrides";
@import "custom-tables";

@import "./custom-theme";