.main-content {
    .wrapperino {
        white-space: nowrap;
        border: none;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        background: #E9EAF2;
        border-radius: 16px;
        display: inline-block;
        max-width: 100%;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        ul.nav-tabs {
            display: flex;
            border-bottom: none;
            &::-webkit-scrollbar {
                //display: none;
            }

            li {
                margin: 0;
                &.active {
                    a {
                        border-radius: 16px;
                        border: none;
                        color: #333;
                        background: #fff;
                        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
                        &:hover {
                            color: #333;
                        }
                    }
                }
                a {
                    font-size: 14px;
                    border-radius: 0;
                    padding: 5px 30px;
                    font-weight: 500;
                    color: #9DA8B5;
                    border: none;
                    background: transparent;
                    margin: 0;
                    transition: color .2s ease-in-out;
                    &:hover {
                        color: #3D4457;
                    }
                }
            }
        }
    }
}