.react-tagsinput {
    background-color: #fff;
    border-bottom: 2px solid #efefef;
    overflow: hidden;
    padding-top: 5px;
}

.react-tagsinput--focused {
    border-bottom: 2px solid #2196f3;
}

.react-tagsinput-tag {
    cursor: pointer;
    overflow: hidden;
    position: relative;
    margin: 5px 3px 5px 0;
    padding: 2px 12px;
    border: 1px solid #888888;
    border-radius: 3px;
    color: #888888;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    display: inline-block;
    vertical-align: middle;

    &:hover{
        padding-left: 8px;
        padding-right: 16px;

        a{
            opacity: 1;
            padding-right: 4px;
        }
    }

    a{
        &::before{
            content: "\f00d";
            font-family: "FontAwesome";
        }

        @extend .animation-transition-ultra-fast;
        color: $default-color;
        cursor: pointer;
        font-size: 12px;
        padding: 2px 0;
        position: absolute;
        right: 0;
        opacity: 0;
        text-align: right;
        text-decoration: none;
        top: 0;
        width: 100%;
        z-index: 2;
    }

    &.tag-blue,
    &.tag-blue a{
         @include label-color($primary-color);
    }
    &.tag-azure,
    &.tag-azure a{
         @include label-color($info-color);
    }
    &.tag-green,
    &.tag-green a{
         @include label-color($success-color);
    }
    &.tag-orange,
    &.tag-orange a{
         @include label-color($warning-color);
    }
    &.tag-red,
    &.tag-red a{
         @include label-color($danger-color);
    }

    &.tag-fill{
        &.tag-blue{
            @include label-color-fill($primary-color);
        }
        &.tag-azure{
            @include label-color-fill($info-color);
        }
        &.tag-green{
            @include label-color-fill($success-color);
        }
        &.tag-orange{
            @include label-color-fill($warning-color);
        }
        &.tag-red{
            @include label-color-fill($danger-color);
        }
    }
    &.tag-fill,
    &.tag-fill a{
        color: $white-color;
    }

}

.react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
}
.react-tagsinput-input {
    background: transparent;
    border: 0;
    color: #777;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 6px;
    margin-top: 1px;
    outline: none;
    padding: 5px;
    width: 80px;
}
