.main-panel {
  width: 100%;
  margin-top: 1px;

  &.wide-panel {
    width: 100%!important;
  }
  .main-content {
    &#home {
      margin: 32px;
      padding: 0;

      @media (max-width: 575px){
        margin: 16px;
      }
    }
    padding: 0 25px;
    min-height: calc(100vh - 90px);

    .tab-pane {
      padding: 0;
    }
  }

  .content-toolbelt {
    //background: rgb(252, 251, 254);
    background: #fff;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    .menu-print {
      >a {
        >div.btn {
          font-size: 12px;
          padding: 5px 10px;
        }
      }
    }
  }
}

.sidebar-mini .main-panel {
  width: 100%;
}

@media (max-width: $screen-md){
  .main-panel {
    width: 100%;
    .main-content {
      padding: 0 15px;
    }
  }

  .sidebar-mini .main-panel {
    width: 100%;
  }
}
