.help-call-offer {
    &.open {
        .help-call-offer-wrapper {
            .help-call-photo-wrapper {
                margin-bottom: 0;
                margin-left: 0;
                flex: 0 0 100px;
                &:after {
                    display: none;
                }
                .photo {
                    width: 100px;
                }
            }

            .card {
                height: 136px;
                pointer-events: all;
                &:hover {
                    .btn-close {
                        display: block;
                    }
                }
            }
        }
    }
    .help-call-offer-wrapper {
        max-width: 767px;
        box-sizing: border-box;
        display: flex;
        .help-call-photo-wrapper {
            margin-right: 10px;
            position: relative;
            flex: 0 0 50px;
            margin-bottom: -50px;
            margin-left: -60px;
            z-index: 1;
            transition: all 0.3s cubic-bezier(.25,.8,.25,1);
            &:after {
                animation: pulse 2s infinite;
                display: block;
                content: '';
                height: 10px;
                width: 10px;
                border-radius: 10px;
                background: $actionColor;
                position: absolute;
                top: 3px;
                right: 3px;
            }
            .photo {
                border-radius: 50%;
                width: 50px;
                box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
                transition: all 0.3s cubic-bezier(.25,.8,.25,1);
                cursor: pointer;
                &:hover {
                    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
                }
            }
        }
        
        .card {
            height: 0;
            overflow: hidden;
            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
            transition: all 0.3s cubic-bezier(.25,.8,.25,1);
            pointer-events: none;
            border-radius: 10px;
            
            .btn-close {
                border: none;
                position: absolute;
                right: -30px;
                padding: 0 0 20px 5px;
                top: 0px;
                display: none;
                img {
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .help-call-offer {
        &.open {
            .help-call-offer-wrapper {
                .card {
                    height: 161px;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .help-call-offer {
        &.open {
            .help-call-offer-wrapper {
                .card {
                    height: auto;
                }
            }
        }
        .help-call-offer-wrapper {

        }
    }
}

@-webkit-keyframes pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba($actionColor, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba($actionColor, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba($actionColor, 0);
    }
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba($actionColor, 0.4);
      box-shadow: 0 0 0 0 rgba($actionColor, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba($actionColor, 0);
        box-shadow: 0 0 0 10px rgba($actionColor, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba($actionColor, 0);
        box-shadow: 0 0 0 0 rgba($actionColor, 0);
    }
  }