#UserZone.user-zone {
  padding: 0 25px;
  width: 100%;
  background: transparent;
  height: 60px;
  transition: all .1s linear;

  .dropdown.open .dropdown-menu {
    margin-top: 8px;
  }

  .nav > li > a {
    padding: 5px;
  }

  .nav {
    .open {
      > a {
        background: none;
      }
    }
  }
  .dropdown-menu.dropdown-menu-right {
    &:after,
    &:before {
      right: 11px;
      left: auto;
    }
  }

  .user-notifications-container {
    &.has-new-notifications {
      &:after {
        content: '';
        display: block;
        position: absolute;
        height: 9px;
        width: 9px;
        background: red;
        top: 5px;
        right: 5px;
        border-radius: 50%;
      }
    }
    p {
      font-size: 14px;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

body.sidebar-mini {
  #UserZone.user-zone {
    //width: calc(100% - 60px);
  }
}

@media (max-width: $screen-md){
  #UserZone.user-zone {
    display: none!important;
  }
}

#userNotifications + ul {
  width: 400px;
 > li {
   border-bottom: 1px solid #E9EAF2;
   padding: 20px;

   &:last-child {
     border-bottom: none;
   }
   > a {
     cursor: unset;
     padding: 0;
     white-space: unset;

     &:hover {
       background: transparent;
     }
   }
 }
}