.content-loader-container {
  width: 100%;
  z-index: 100;
  background-color: rgba(255,255,255,0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;

  .content-loader {
    left: 50%;
    top: 50%;
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid $defaultColor;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
  }
}


.main-loader-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 998;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.8);

}

.main-loader {
  width: 130px;
  height: 170px;
  position: relative;
}
.main-loader::before, .main-loader::after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  bottom: 30px;
  left: 15px;
  z-index: 1;
  transform: scale(0);
  transition: all 0.2s ease;
}
.main-loader .txt {
  width: 120%;
  text-align: center;
  position: absolute;
  bottom: -30px;
  left: -7%;
  font-family: 'Abel', sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  color: white;
}
.main-loader .code {
  position: absolute;
  z-index: 99;
  bottom: -3px;

  -webkit-animation-name: spaceboots;
  -webkit-animation-duration: 0.8s;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
}
.main-loader .box {
  width: 100%;
  height: 140px;
  display: block;
  color: white;
  position: absolute;
  top: -70px;
  left: 0px;
  z-index: 2;
  overflow: hidden;
}
.main-loader .box::before, .main-loader .box::after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.main-loader .box:nth-child(1)::before {
  font-size: 20px;
  content: "\f13b";
  animation: a 1.1s linear infinite;
}
.main-loader .box:nth-child(1)::after {
  content: "\f13c";
  animation: b 1.3s linear infinite;
}
.main-loader .box:nth-child(2)::before {
  content: "\f121";
  font-size: 25px;
  animation: c 0.9s linear infinite;
}
.main-loader .box:nth-child(2)::after {
  content: "\f19a ";
  animation: d 0.7s linear infinite;
}
.main-loader.amit::before, .main-loader.amit::after {
  transform: scale(1);
}

@keyframes a {
  0% {
    transform: translate(30px, 0) rotate(30deg);
    opacity: 0;
  }
  100% {
    transform: translate(30px, 150px) rotate(-50deg);
    opacity: 1;
  }
}
@keyframes b {
  0% {
    transform: translate(50px, 0) rotate(-40deg);
    opacity: 0;
  }
  100% {
    transform: translate(40px, 150px) rotate(80deg);
    opacity: 1;
  }
}
@keyframes c {
  0% {
    transform: translate(70px, 0) rotate(10deg);
    opacity: 0;
  }
  100% {
    transform: translate(60px, 150px) rotate(70deg);
    opacity: 1;
  }
}
@keyframes d {
  0% {
    transform: translate(30px, 0) rotate(-50deg);
    opacity: 0;
  }
  100% {
    transform: translate(45px, 150px) rotate(30deg);
    opacity: 1;
  }
}
@-webkit-keyframes spaceboots {
  0% {
    -webkit-transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    -webkit-transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    -webkit-transform: translate(0px, 2px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(2px, 1px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    -webkit-transform: translate(2px, 2px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
  }
}