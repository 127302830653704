$cta-color: #007AFF;

.card.card-plan {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    box-shadow: none;
    background: transparent;
    text-align: center;
    min-height: 500px;
    padding: 35px;
    font-size: 14px;

    .card-header {
        .card-title {
            margin-top: 0;
            margin-bottom: 30px;
            text-transform: uppercase;
            font-weight: bold;
        }

        p {
            font-size: 14px;
        }
        
        .card-highlighted-message {
            margin-bottom: 30px;
            text-align: left;

            i {
                color: rgb(69, 203, 131);
            }
        }
    }

    .card-footer {
        .price-tag {
            margin: 0;
            margin-bottom: 5px;
            font-size: 24px;
            font-weight: bold;
            .price-tag-month {
                font-size: 12px;
                color: #9A9A9A;
                margin-left: 5px;
                font-weight: normal;
            }
        }

        .btn-plan {
            border-color: $cta-color;
            color: $cta-color;
            font-weight: bold;
            &.is-ongoing {
                border: none;
                opacity: 1;
                color: #9A9A9A;
            }
        }
    }

    &.is-promoted {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        padding: 32px;
        border: 2px solid $cta-color;

        .card-footer {
            .btn-plan {
                background: $cta-color;
                color: white;
                opacity: 1;
                font-size: 20px;
                display: block;
                &.is-ongoing {
                    background: transparent;                    
                    color: rgb(69, 203, 131);
                    font-weight: bold;
                }
            }
        }
    }
}