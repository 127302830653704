input[type='date']::-webkit-calendar-picker-indicator,
input::-webkit-calendar-picker-indicator,
input[type='date' i]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
    appearance: none;
    opacity: 0;
}


.planActivo {
    width: 120px
}

.tdlabel {
    text-align: center;
    padding-top: 15px !important;
}


.reduce-div-paddings div {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}