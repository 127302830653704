.dropdown-with-icons{
  > li > a{
    line-height: 28px;
  }

  .dropdown-menu{
    min-width: 200px;

    i{
      text-align: center;
      line-height: 28px;
      float: left;

      &[class^="fa"]{
        font-size: 24px;
        width: auto;
        margin-right: 10px;
      }
    }
  }
}

.dropdown {
  .dropdown-toggle {
    &.no-arrow {
      +.dropdown-menu {
        margin-top: 5px;
        li {
          &:first-child {
            .checkbox {
              margin-top: 5px;
            }
          }
          &:last-child {
            .checkbox {
              margin-bottom: 5px;
            }
          }
        }
        .checkbox {
          padding: 8px 16px;
          margin: 0;
          label {
            width: 100%;
          }
        }
        &::before,
        &::after {
          display: none;
        }
      }
    }
  }
}