@mixin btn-simple ($accent: none) {
  border: $none;
  color: $accent;
  font-size: $font-size-medium;
  padding: $padding-base-vertical $padding-base-horizontal;

  &.btn-icon{
    padding: $padding-base-vertical;
  }

  &:hover,
  &:focus,
  &:active,
  &.active,
  &:active:focus,
  &:active:hover,
  &.active:focus,
  &.active:hover,
  .open > &.dropdown-toggle {
    background-color: $transparent-bg;
    color: $accent;
  }
}