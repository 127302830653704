// TODO: Come back to check animation.
.card {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    &.card-analisis-gastos {
        box-shadow: none;
        border: none;
        border-radius: 6px;
    }

    &.card-consejo {
        background: #ebebf3;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        // opacity: 0;
        // transition: all .3s;
        // transform: translate(-60%);
        // display: none;

        &.is-active {
            opacity: 1;
            display: block;
            transform: translateX(0);
        }

        .card-header {
            padding: 10px 20px;
            position: relative;
            .taxbot {
                position: absolute;
                left: -13px;
                top: 8px;
            }
        }

        .card-body {
            padding: 0 20px 10px 20px;
        }

        .card-footer {
            padding: 0 20px 10px 20px;
            text-decoration: underline;
            a {
                color: #1e2759;
                padding: 0;
                font-weight: bold;

                &:hover {
                    color: #1e2759;
                }
            }
        }

        p {
            font-size: 14px;
        }
    }
}